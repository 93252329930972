import {Div} from "atomize"
import {get} from "lodash"
import {graphql} from "gatsby"
import React, {useEffect} from "react"
import ls from "local-storage"
import {useMutation, useQuery} from "@apollo/client"

import {SEO} from "../components/common/SEO"
// import useEmergence from "../hooks/useEmergence"
import Layout from "../components/common/Layout"
import {getPageModules} from "../services/pageService"
import GraphQLErrorList from "../components/common/GraphqQLErrorList"
import {saveCheckoutInLocalStorage} from "../services/cartService"
import {
  CHECKOUT_BY_ID,
  CHECKOUT_CREATE,
  TOGGLE_CART,
} from "../graphql/queries"

const Page = props => {
  //***************This checkoutData has to be in parent of all the instance of moduleContentWithPropductCards */
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
    },
  })

  const {data: checkoutData, refetch: refechCheckoutData} = useQuery(
    CHECKOUT_BY_ID,
    {
      // skip: !ls("lastCheckoutID"),
      variables: {id: ls("lastCheckoutID")},
    }
  )

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      //window.history.pushState("header", "Not Pot", "/#cart")
      toggleCart({variables: {cartDrawerVisible: true}})
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      console.log(ls("lastCheckoutID"))
      refechCheckoutData({
        // skip: !ls("lastCheckoutID"),
        variables: {id: ls("lastCheckoutID")},
      })
    },
  })
  //********************************** */

  // useEmergence()
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    )
  }

  const site = (data || {}).site
  const location = props.location

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const page = data.page || data.route.page
  const legalRoutes = get(data, "legalRoutes.edges", [])
  const helpdeskRoutes = get(data, "helpdeskRoutes.edges", [])
  const modules = getPageModules({
    legalRoutes,
    helpdeskRoutes,
    page,
    location,
    checkoutData,
    checkoutCreate,
  })
  const pageTitle = data.route && !data.route.useSiteTitle && page.title
  console.log(process.env)
  return (
    <Layout site={site}>
      <SEO
        title={pageTitle}
      />
      {modules}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }

    legalRoutes: allSanityRoute(filter: { type: { eq: "legal" } }) {
      edges {
        node {
          slug {
            current
          }
          page {
            title
          }
        }
      }
    }

    helpdeskRoutes: allSanityRoute(filter: { type: { eq: "helpdesk" } }) {
      edges {
        node {
          slug {
            current
          }
          page {
            title
          }
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`
